<template>
  <div></div>
</template>
<script>
import { uiHelper } from '@/util'
export default {
  name: 'vendorPopUp',
  data: () => ({}),
  computed: {},

  created() {
    this.initializePaymentVendorPage()
  },
  methods: {
    initializePaymentVendorPage() {
      let data = uiHelper.getLocalStorage('paymentVendorUrl')
      window.location = data
      uiHelper.setLocalStorage('paymentVendorUrl', '', -100)
    }
  }
}
</script>

<style lang="scss" scoped>
html {
  overflow: auto !important;
  width: 100%;
  height: 100%;
}
.game-frame-container {
  height: 100%;
  background-color: black;
}
.gameFrame {
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
}
</style>
